import { useCallback, useContext, useEffect, useState } from 'react'
import s from './Game.module.css'
import PlayingField from './PlayingField/PlayingField'
import Control from './Control/Control'
import { useSearchParams } from 'react-router-dom'
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import { InitialState, SpinResult } from '../../types/Messages'
import InitialStateContext from '../../context/InitialStateContext'
import BalanceContext from '../../context/BalanceContext'
import GameConfigContext from '../../context/GameConfigContext'
import Api from '../../api'
import Popup from '../Popup/Popup'
import { useSounds } from '../../utils/useSounds'
import PopupsOpenContext, { IPopups } from '../../context/PopupsContext'
import ProvablyFairnessModal from './ProvablyFairnessPopup/ProvablyFairnessPopup'
import RulesPopup from './RulesPopup/RulesPopup'
import ErrorContext from '../../context/ErrorContext'
import yo from '../../assets/yo.png'
import OldHashAndKeyContext from '../../context/OldHashAndKeyContext'
import {randomOdd} from "../../utils/randomOdd";
import {useTranslation} from "../../hooks/useTranslation";

const Game = () => {
    const { t } = useTranslation()
    const [, setErr] = useContext(ErrorContext)

    const { betSound, winSound, autoplaySound, crashSound, clickSound} = useSounds()

    const [params] = useSearchParams()
    const partnerId = params.get('PartnerId')
    const gameName = params.get('GameName')
    const oneTimeToken = params.get('OneTimeToken')
    const mode = params.get('Mode')

    const [connection, setConnection] = useState<HubConnection | null>(null)

    const [balance, setBalance] = useContext(BalanceContext)
    const [initialState, setInitialState] = useContext(InitialStateContext)

    const [{ isMute, isTurboMode }] = useContext(GameConfigContext)
    const [popupOpen, setPopupOpen] = useContext(PopupsOpenContext)

    const openPopup = useCallback(
        (popup: keyof IPopups, value: boolean) => {
            setPopupOpen(prev => ({
                ...prev,
                [popup]: value,
            }))
        },
        [popupOpen]
    )

    const [history, setHistory] = useState<IRoundResult[]>([])
    const [oldHashAndKey, setOldHashAndKey] = useContext(OldHashAndKeyContext)

    // Control
    const [amount, setAmount] = useState<string>('0')
    const [multiplier, setMultiplier] = useState('0')
    const [number, setNumber] = useState('50')
    const [marketType, setMarketType] = useState(false)

    const changeValue = useCallback(
        (
            name: 'amount' | 'number' | 'numberOfRounds',
            prevValue: string,
            newValue: string | ((prev: string) => string)
        ) => {
            if (typeof newValue !== 'string') {
                newValue = newValue(prevValue)
            }

            switch (name) {
                case 'amount' :
                    setAmount(newValue)
                    localStorage.setItem(process.env.REACT_APP_AMOUNT_KEY as string, newValue)
                    break
                case 'number':

                    if (+newValue < 2 || +newValue > 98) {
                        break
                    }

                    setNumber(newValue)
                    localStorage.setItem(process.env.REACT_APP_NUMBER_KEY as string, newValue)
                    break
                case 'numberOfRounds':

                    if ((newValue !== '' && +newValue < 1) || (+newValue > 10000)) {
                        break
                    }

                    setNumberOfRounds(newValue)
                    break
            }
        },
        [],
    )

    const [isAutobet, setIsAutobet] = useState(false)
    const [isAutobetRunning, setIsAutobetRunning] = useState(false)

    useEffect(() => {
        if (isAutobet) {
            autoplaySound()
        } else {
            setIsAutobetRunning(false)
        }
    }, [isAutobet])

    const [numberOfRounds, setNumberOfRounds] = useState('10')

    const [isGame, setIsGame] = useState(false)
    const [odd, setOdd] = useState<number>(50)
    const [gameResult, setGameResult] = useState<GameResult>('')

    useEffect(() => {
        clickSound()
    }, [number, odd])

    const [animationOddTimer, setAnimationOddTimer] = useState<NodeJS.Timer | null>(null)

    const handleInitialState = (data: InitialState) => {
        setBalance(data.balance)
        setInitialState(data)
    }

    const handleSpinResult = (data: SpinResult) => {
        if (data.responseCode > 0) {
            setErr(t('UNEXPECTEDERR'))

            if (animationOddTimer) {
                clearInterval(animationOddTimer)
            }

            return
        }

        setHistory(prev => [
            { odd: data.result.resultNumber, status: data.spinStatus },
            ...prev,
        ])
        setOldHashAndKey(data.oldResultHashAndKey)

        if (animationOddTimer) {
            clearInterval(animationOddTimer)
        }

        setOdd(data.result.resultNumber)

        if (data.spinStatus === 0) {
            crashSound()

            setGameResult('lose')
            setBalance(data.balance)
        }

        if (data.spinStatus === 1) {
            winSound()

            setGameResult('win')
            setBalance(data.balance + data.winAmount)
        }

        if (!isAutobet) {
            setIsGame(false)
        }
    }

    const stopAutobet = () => {
        setIsAutobet(false)
        setIsAutobetRunning(false)
        setIsGame(false)
    }

    const runAutoBet = async () => {
        if (!isAutobet || +numberOfRounds < 1) return

        if (+amount > balance) {
            setErr(t('NOBALANCE'))
            setIsAutobet(false)
            return
        }

        setIsAutobetRunning(true)

        setNumberOfRounds(prev => (+prev - 1).toString())

        await spin(+amount, +number, +marketType)
    }

    useEffect(() => {
        if (!gameResult) return

        let timerId: NodeJS.Timeout | null = null

        if (isAutobet) {
            if (+numberOfRounds > 0) {
                timerId = setTimeout(() => runAutoBet(), 1000)
            } else {
                stopAutobet()
            }
        } else {
            stopAutobet()
        }

        return () => {
            if (timerId) {
                return clearTimeout(timerId)
            }
        }
    }, [gameResult])

    const spin = async (amount: number, number: number, marketType: number) => {
        if (!connection) return

        if (amount > balance) {
            setErr(t('NOBALANCE'))
            return
        }

        if (+multiplier.replace('x', '') < 1.01) {
            setErr(t('MULTIPLIERERR'))
            return
        }

        if (amount > initialState.limits.maxBet || amount < initialState.limits.minBet) {
            return
        }

        if (number < 2 || number > 98) {
            return
        }

        setGameResult('')
        setIsGame(true)

        betSound()

        setBalance(prev => prev - amount)

        const data = {
            amount,
            spinData: {
                number,
                marketType,
            },
        }

        if (isTurboMode) {
            await connection.invoke('Spin', data)
        } else {
            let counter = 0
            const delay = 250

            const timerId = setInterval(() => {
                setOdd(randomOdd())
                clickSound()
                counter += delay

                if (counter === 1000) {
                    connection.invoke('Spin', data)
                }
            }, delay)

            setAnimationOddTimer(timerId)
        }

    }

    useEffect(() => {
        const connect = async () => {
            let token: string | null = null

            if (oneTimeToken) {
                const { accessToken } = await Api().auth.login(oneTimeToken)
                token = accessToken
            }

            let wsUrl = process.env.REACT_APP_WS as string

            if (token) {
                wsUrl = wsUrl + '?access_token=' + token
            }

            const c = new HubConnectionBuilder()
                .withUrl(wsUrl)
                .withAutomaticReconnect()
                .build()
            setConnection(c)
        }
        connect()
    }, [])

    useEffect(() => {
        if (!connection) return

        const runConnection = async () => {
            connection.on('InitialStateResult', handleInitialState)

            await connection.stop()
            await connection.start()

            let initStateDto: Object = { partnerId, gameName }

            if (mode === 'demo') {
                initStateDto = { ...initStateDto, isDemo: true }
            }

            await connection.invoke("InitialState", initStateDto )
        }
        runConnection()
    }, [connection])

    useEffect(() => {
        if (!connection) return

        connection.off('SpinResult')
        connection.on('SpinResult', handleSpinResult)
    }, [connection, isMute, animationOddTimer, isAutobet])

    if (!Object.keys(initialState).length) {
        return (
            <div className="h-screen flex items-center justify-center">
                <img src={yo} alt="Yo" className="w-[200px]" />
            </div>
        )
    }

    return (
        <div className={s.game}>
            <PlayingField
                gameResult={gameResult}
                odd={odd}
                history={history}
                number={number}
                changeValue={changeValue}
                isAutobetRunning={isAutobetRunning}
                marketType={marketType}
            />
            <Control
                isGame={isGame}
                amount={amount}
                setAmount={setAmount}
                multiplier={multiplier}
                setMultiplier={setMultiplier}
                number={number}
                setNumber={setNumber}
                isAutobet={isAutobet}
                setIsAutobet={setIsAutobet}
                numberOfRounds={numberOfRounds}
                setNumberOfRounds={setNumberOfRounds}
                spin={spin}
                runAutobet={runAutoBet}
                isAutobetRunning={isAutobetRunning}
                marketType={marketType}
                setMarketType={setMarketType}
                changeValue={changeValue}
            />

            {oldHashAndKey && (
                <Popup
                    active={popupOpen.provablyFairness}
                    setActive={value => openPopup('provablyFairness', value)}
                >
                    <ProvablyFairnessModal lastRound={oldHashAndKey} />
                </Popup>
            )}

            <Popup
                active={popupOpen.rules}
                setActive={value => openPopup('rules', value)}
            >
                <RulesPopup />
            </Popup>
        </div>
    )
}

export default Game

export type GameResult = '' | 'win' | 'lose'

export enum MarketTypes {
    'under'= 0,
    'over' = 1,
}

export interface IRoundResult {
    odd: number
    status: number
}
